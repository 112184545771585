import Breadcrumbs from "common/components/Breadcrumbs";
import Container from "common/components/Container";
import SEO from "common/components/SEO";
import DefaultLayout from "common/layouts/Default";
import React from "react";

const Renowacje: React.FC = () => {
  return (
    <DefaultLayout>
      <SEO
        title="Renowacje"
        description="Kompleksowe renowacje fortepianów i pianin, przywrócę świetność Twojego instrumentu!"
      />
      <Breadcrumbs />

      <div className="h-screen bg-[url('../../../static/images/renovation-pattern.png')] bg-cover bg-center py-16 sm:py-32">
        <Container customClasses="text-center lg:max-w-3xl 2xl:max-w-6xl lg:!px-0 mx-auto relative">
          <h1 className="text-3xl font-semibold md:text-4xl lg:text-5xl">
            Kompleksowe renowacje fortepianów i pianin
          </h1>
          <p className="mt-6 text-lg md:text-xl lg:mt-8 lg:text-2xl">
            <strong>Renowacja starego instrumentu</strong> to długotrwały i
            żmudny proces. Jednak wieńczony sukcesem daje wielką satysfakcję
            zarówno właścicielowi jak i mnie.{" "}
            <strong className="text-amber-300">
              Przywrócę świetność Twojego instrumentu! Odtworzę walory
              brzmieniowe i estetyczne.
            </strong>{" "}
            Zapraszam!
          </p>

          <p className="mt-8 text-zinc-600 lg:text-lg">
            Dodatkowych informacji udzielę telefonicznie lub mailowo.
          </p>
        </Container>
      </div>
    </DefaultLayout>
  );
};

export default Renowacje;
